(function (exports) {
  if (exports) {//esto permite importar este factory en backend
    exports.factory = factory();
    var moment = require('moment');
    var PentaError = require('../../../../../utils/PentaError');
    return
  } else {
    var moment = window.moment;
  }

  angular.module('pentaApp').factory("reservation.factory", factory);
  factory.$inject = ['$q', 'reservation.resource', 'payment.factory'];
  function factory($q, reservationResource, paymentFactory) {
    return {
      takeTurn: takeTurn,
      generateHours: generateHours,
      checkAllModes: checkAllModes,
      checkAllModesMultiQueue: checkAllModesMultiQueue,
      calculateAvailability: calculateAvailability,
      isMode567: isMode567,
      getIntervalsForDate: getIntervalsForDate,
      getAvailableSlotsMode234: getAvailableSlotsMode234,
      isMode234: isMode234,
      getDowInterval: getDowInterval,
      getDowIntervalStartEnd: getDowIntervalStartEnd
    }


    // Esta función devuelve true si para la hora determinada hay lugar para sacar el turno para todos los motivos entregados uno después del otro
    /*date, fecha en la que se quiere hacer le turno
      queues: es un array de motivos ordenado (esto va a calcular la disponibilidad para los turnos sacados en ese orden),
      router: es la sucursal (debe tener adentro, obligatorio, router.reservationConfig),
      routerReservations: son todas las reservas hechas por otras personas,
      reservationSlots: es la cantidad de lugares solicitados (opcional, por defecto 1),
      posTables: la lista de mesas completas,
      holidays: lista de los feriados completos,
      posAreas: lista de posAreas, OPCIONAL/CONDICIONAL, cuando queue no tiene queue.router.area
    */
    function checkAllModesMultiQueue(date, queues, router, routerReservations, reservationSlots, posTables, holidays, posAreas) {
      var mainQueue = queues[0];
      if (!checkAllModes(date, mainQueue, routerReservations, router, reservationSlots, posTables, holidays, posAreas)) return false;
      var lastDate = date;
      var lastMeta = mainQueue.router.attendingTime && mainQueue.router.attendingTime.max || 30;
      return queues.every(function (nextReservationQueue, index) {
        if (index === 0) return true;
        var nextReservationDate = moment(lastDate).add(lastMeta, "minutes");
        if (!checkAllModes(nextReservationDate.toDate(), nextReservationQueue, routerReservations, router, reservationSlots, posTables, holidays, posAreas)) return false;
        lastDate = nextReservationDate;
        lastMeta = nextReservationQueue.router.attendingTime && nextReservationQueue.router.attendingTime.max || 30;
        return true;
      })
    }

    function getDowInterval(date, queue, router) {
      if (!router || !router.reservationConfig || !router.reservationConfig.dow || !router.utcOffset || !date || !queue) throw new PentaError('No hay suficiente información para calcular la disponibilidad (dowInterval)');
      var thisDay = moment(date).utcOffset(router.utcOffset).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      var dow = thisDay.day();
      var dowIntervals = filterDowIntervalsByQueue(router.reservationConfig.dow[dow], queue._id);
      if (!dowIntervals) return 0;
      let candidates = dowIntervals.filter(function (dowInterval) {
        // Validamos el dateFrom y dateEnd de dowIntervals
        if (!checkDowIntervalValidity(dowInterval, router, thisDay)) return;
        // buscamos que coincida la fecha de la reserva con la del intervalo
        var dowIntervalStartEnd = getDowIntervalStartEnd(date, router, dowInterval);
        if (date >= dowIntervalStartEnd.start && date <= dowIntervalStartEnd.end) return true;
      })
      // Se ordenan de mayor prioridad a menor prioridad según fecha desde/hasta
      candidates.sort(function (a, b) {
        if (!a || !b) return 0;
        if (a.dateFrom && a.dateTo && !b.dateFrom && !b.dateTo) return -1; // si a tiene fecha de inicio y fin, y b no, entonces gana a
        if (!a.dateFrom && !a.dateTo && b.dateFrom && b.dateTo) return 1; // si b tiene fecha de inicio y fin, y a no, entonces gana b
        if (a.dateFrom && a.dateTo && b.dateFrom && b.dateTo) {
          let deltaA = moment(a.dateTo).diff(moment(a.dateFrom));
          let deltaB = moment(b.dateTo).diff(moment(b.dateFrom));
          return deltaA - deltaB // Si ambos tienen gana el que tiene el rango mas corto (el que sería mas específico)
        }
        return 0 // cualquier otro caso empatan
      })
      return candidates[0];
    }

    // recibe el array de todos los intervalos de un dow determinado, y los filtra para ese queue
    // dowIntervals: array de dowintervals
    // queueId: Id en string de la queue
    function filterDowIntervalsByQueue(dowIntervals, queueId) {
      if (!dowIntervals?.length) return []
      var result = dowIntervals.filter(function (f) { return f && (!f.queue || f.queue === queueId) });
      // Si tiene al menos una personalizacion en el dia, ignoro todas las personalizaciones globales
      if (result.find(function (f) { return f.queue }))
        result = result.filter(function (f) { return f.queue });
      return result;
    }

    // Esta función devuelve true si para la hora determinada hay lugar para sacar el turno para un motivo dado
    /*date fecha de turno
      queue es el motivo, !!IMPORTANTE: queue.router debe tener el objeto del array queue.router que coincida con el router enviado (esto es un parche ya que el api de la app devuelve los datos así)
      routerReservations son todas las reservas hechas por otras personas,
      router es el objeto de la sucursal
      reservationSlots es la cantidad de lugares solicitados,
      posTables la lista de mesas completas,
      holidays la lista de feriados
    */
    function checkAllModes(date, queue, routerReservations, router, reservationSlots, posTables, holidays, posAreas, availableSlots) {
      if (!queue || !date) return false;
      if (!queue.router) queue.router = queue.routers.find(function (routerQueue) { return routerQueue._id.toString() === router._id.toString() });
      if (!queue.router) return false;
      if (posAreas && posAreas.length && queue.router.area && !queue.router.area._id) {
        // Desde que se hizo esto originalmente en la app, el api devolvía en queue.router un objeto y en queue.router.area otro objeto de area
        // Esto es un poco confuso pero para no cambiar toda la lógica se vuelve a competar esta información acá
        queue.router.area = posAreas.find(function (posArea) { return posArea.router.toString() === router._id.toString() })
      }
      if (!reservationSlots) reservationSlots = 1;
      if (isHoliday(date, router, holidays)) return false;
      if (isNaN(availableSlots) && (!queue.router.mode || queue.router.mode === 'MODE2' || queue.router.mode === 'MODE3' || queue.router.mode === 'MODE4'))
        // Esto lo agrego para que no sea necesario llamar la función con este dato ya que se llama desde muchos lugares
        availableSlots = getAvailableSlotsMode234(date, queue, router, routerReservations)
      if (!queue.router.mode) return mode234(availableSlots, reservationSlots);
      else if (queue.router.mode === 'MODE2' || queue.router.mode === 'MODE3' || queue.router.mode === 'MODE4') return mode234(availableSlots, reservationSlots);
      else if (queue.router.mode === 'MODE5' || queue.router.mode === 'MODE6' || queue.router.mode === 'MODE7') return mode567(date, queue, routerReservations, router, posTables, reservationSlots);
      else if (queue.router.mode === 'MODE8' || queue.router.mode === 'MODE9' || queue.router.mode === 'MODE10') return mode8910(date, queue, routerReservations, router, reservationSlots);
      else return false
    }

    function mode234(availableSlots, reservationSlots) {
      return reservationSlots <= availableSlots
    }

    // convierte un dowinterval a una fecha
    function getDowIntervalStartEnd(date, router, dowInterval) {
      var thisDay = moment(date).utcOffset(router.utcOffset).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      var dowIntervalStart = thisDay.clone().set({ milliseconds: dowInterval.start });
      var dowIntervalEnd = thisDay.clone().set({ milliseconds: dowInterval.end });
      if (dowIntervalEnd < dowIntervalStart) dowIntervalEnd.add(1, 'day');

      return { start: dowIntervalStart, end: dowIntervalEnd }
    }

    function mode567(date, queue, routerReservations, router, posTables, reservationSlots) {
      // Modo calendarizado por locación
      if (!queue || !queue.router || !queue.router.area || !queue.router.area._id) return false;
      var posiblesPosTables = posTables.filter(function (f) {
        if (!f.slots) f.slots = 1;
        var match = (!queue.router.area || f.area === queue.router.area._id) && reservationSlots <= f.slots
        if (match) {
          f.calendar = [];
          return true;
        }
      });
      var meta = queue.router.attendingTime && (queue.router.attendingTime.meta || queue.router.attendingTime.max) || 30
      var dowInterval = getDowInterval(date, queue, router);
      if (!dowInterval) return false;
      var absoluteHourStart = moment(date).utcOffset(0, true);
      absoluteHourStart = absoluteHourStart.hours() * 3600000 + absoluteHourStart.minutes() * 60000 + absoluteHourStart.seconds() * 1000 + absoluteHourStart.milliseconds()
      // Se agrega un + 1 al fin del intervalo porque la mayoría termina en 999 milisegundos, y si el start mas el meta justo da 1ms mas (cosa muy probable) lo deja afuera
      if (absoluteHourStart + meta * 60000 > (dowInterval.end + 1)) return false; // No puede reservar un turno, si cae fuera del rango configurado
      // EJ: Si configuro un rango de 9 a 12, y la meta de atencion es de 2hs, solo debe habilitarme 9 y 10, si scara a las 11, solo hay una hora
      // para atenderme y no entra...

      routerReservations.forEach(function (reservation) {
        if (!reservation.posTable) return;
        var location = posiblesPosTables.find(function (f) { return f._id === reservation.posTable });
        if (!location) return;
        location.calendar.push({
          start: moment(reservation.date),
          end: moment(reservation.date).add(reservation.meta || meta, 'minutes')
        })
      })
      var hourEnd = moment(date).add(meta, 'minutes');

      // Revisa que no supere la capacidad del área (si tuviera una capacidad configurada)
      var areaSlots = queue.router && queue.router.area && queue.router.area.slots;
      if (areaSlots) {
        var totalReservations = 0;
        routerReservations.forEach(function (reservation) {
          if (reservation.posArea && reservation.posArea !== queue.router.area._id) return
          if (moment(reservation.date) >= date && moment(reservation.date) < hourEnd) totalReservations += reservation.slots || 1;
        })
        if (totalReservations + (reservationSlots || 1) > areaSlots) return; //si supera la capacidad del area, chau
      }

      // Lista todas las mesas que no tengas ninguna reserva para el rango actual
      var resultPosTable = posiblesPosTables.filter(function (posTable) {
        // solo pasan aquellas mesas que no tengan otras reservas solapadas con la nuestra
        return !posTable.calendar.find(function (event) { return event.end > date && event.start < hourEnd })
      })
      resultPosTable.sort(function (a, b) { return a.slots - b.slots });
      resultPosTable = resultPosTable[0];
      return resultPosTable;
    }

    function mode8910(date, queue, routerReservations, router, reservationSlots) {
      // Modo calendarizado por area
      // recordar que lamentablemente router y area se popularon desde la api cuando se hizo esto por primera vez
      // entonces ahora ambos tienen que ser un obj
      if (!queue || !queue.router || !queue.router.area || !queue.router.area._id) return false;
      var areaCalendar = [];
      var meta = queue.router && queue.router.attendingTime && (queue.router.attendingTime.meta || queue.router.attendingTime.max) || 30;
      var areaSlots = queue.router && queue.router.area && queue.router.area.slots || 0;
      var dowInterval = getDowInterval(date, queue, router);
      if (!dowInterval) return false;
      var absoluteHourStart = moment(date).utcOffset(0, true);
      absoluteHourStart = absoluteHourStart.hours() * 3600000 + absoluteHourStart.minutes() * 60000 + absoluteHourStart.seconds() * 1000 + absoluteHourStart.milliseconds()
      // Se agrega un + 1 al fin del intervalo porque la mayoría termina en 999 milisegundos, y si el start mas el meta justo da 1ms mas (cosa muy probable) lo deja afuera
      if (absoluteHourStart + meta * 60000 > (dowInterval.end + 1)) return false; // No puede reservar un turno, si cae fuera del rango configurado
      // EJ: Si configuro un rango de 9 a 12, y la meta de atencion es de 2hs, solo debe habilitarme 9 y 10, si scara a las 11, solo hay una hora
      // para atenderme y no entra...

      routerReservations.forEach(function (reservation) {
        if (reservation.posArea && reservation.posArea !== queue.router.area._id) return
        areaCalendar.push({
          start: moment(reservation.date),
          end: moment(reservation.date).add(reservation.meta || meta, 'minutes'), // se pone por defecto meta del turno que se esta sacando, xq es un bug que ya existia pero en ciertos clientes esto funciona
          slots: reservation.slots
        })
      })
      var hourEnd = moment(date).add(meta, 'minutes');
      var calendarOccupiedSlots = 0;
      areaCalendar
        .filter(function (calendarItem) {
          return calendarItem.end > date && calendarItem.start < hourEnd // dejo las reservas superpuestas a la que se quiere sacar
        })
        .forEach(function (f) { calendarOccupiedSlots += f.slots });
      return calendarOccupiedSlots + (reservationSlots || 1) <= areaSlots;
    }

    //- retorna true/false si el dia es feriado
    function isHoliday(date, router, holidays) {
      var dateString = moment(date).utcOffset(router.utcOffset).format("YYYYMMDD");
      var holiday = holidays.find(function (holiday) { return moment(holiday.date).utcOffset('+0000').format('YYYYMMDD') === dateString });
      if (holiday) {
        if (!holiday.routers || !holiday.routers.length) return true;
        if (holiday.routers.find(function (r) { return r === router._id })) return true;
      }
      return false
    }

    function generateHours(dowIntervals, queue, thisDay, reservationConfig, router, holidays) {
      if (!dowIntervals || !dowIntervals.length) return [];
      var includeFrom = moment();
      if (reservationConfig && reservationConfig.disallowReservationsBefore) includeFrom.add(reservationConfig.disallowReservationsBefore, 'milliseconds');
      var hours = [];
      dowIntervals = filterDowIntervalsByQueue(dowIntervals, queue._id);
      dowIntervals.forEach(function (dowInterval) {
        if (!checkDowIntervalValidity(dowInterval, router, thisDay)) return;
        dowInterval.usedSlots = 0;
        var dowIntervalStartEnd = getDowIntervalStartEnd(thisDay, router, dowInterval);
        while (dowIntervalStartEnd.start < dowIntervalStartEnd.end) {
          var newItem = {
            start: dowIntervalStartEnd.start.toDate(),
            end: dowIntervalStartEnd.start.add(queue.reservationInterval || 30 * 60000, 'milliseconds').toDate(),
            dowInterval: dowInterval
          };
          if (newItem.end < includeFrom) continue;
          if (isHoliday(newItem.start, router, holidays)) continue;
          // se fija si el intervalo se encuentra pisado por una fecha especial
          if (reservationConfig?.special?.length && reservationConfig.special.find(specialDay => (newItem.end > new Date(specialDay.start)) && (newItem.start < new Date(specialDay.end))))
            continue
          hours.push(newItem);
        }
      })
      return hours;
    }

    function checkDowIntervalValidity(dowInterval, router, thisDay) {
      if (dowInterval.dateFrom) {
        var dateFrom = moment(dowInterval.dateFrom).utcOffset(router.utcOffset).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
        if (dateFrom && thisDay.isBefore(dateFrom)) return false
      }
      if (dowInterval.dateTo) {
        var dateTo = moment(dowInterval.dateTo).utcOffset(router.utcOffset).set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });
        if (dateTo && thisDay.isAfter(dateTo)) return false
      }
      return true;
    }

    // Esta función devuelve los intervalos de disponibilidad ya convertidos a la fecha que se indica.
    // date: fecha para la que se quieren recibir los intervalos
    // dowIntervals: array de todos los intervalos,
    // router: se usa para sacar el utcOffset, isHoliday lo necesita recibir como objeto de router.
    // holidays: array de feriados.
    // queue: objeto cola para la que se quieren obtener los intervalos
    function getIntervalsForDate(date, dowIntervals, router, holidays, queue) {
      if (!dowIntervals || !dowIntervals.length) return [];
      var hours = [];
      dowIntervals = dowIntervals.filter(function (f) { return !f.queue || f.queue === queue._id });
      if (dowIntervals.find(function (f) { return f.queue })) dowIntervals = dowIntervals.filter(function (f) { return f.queue });
      dowIntervals.forEach(function (dowInterval) {
        if (dowInterval.dateFrom && date.isBefore(dowInterval.dateFrom)) return
        if (dowInterval.dateTo && date.isAfter(dowInterval.dateTo)) return
        dowInterval.usedSlots = 0;
        var dowIntervalStart = date.clone().utcOffset(router.utcOffset).startOf('day').set({ milliseconds: dowInterval.start });
        var dowIntervalEnd = date.clone().utcOffset(router.utcOffset).startOf('day').set({ milliseconds: dowInterval.end });
        if (dowIntervalEnd < dowIntervalStart) dowIntervalEnd.add(1, 'day');
        var newItem = {
          start: dowIntervalStart.toDate(),
          end: dowIntervalEnd.toDate(),
          dowInterval: dowInterval
        };
        if (holidays.length && isHoliday(date, router, holidays)) return
        hours.push(newItem);
      })
      return hours
    }

    /*
    turnsConfig: Obj {}, objeto de configuración de turnos de la empresa (en enterprise.model -> turnsConfig)
    */
    async function takeTurn(queues, routerReservations, reservation, allAdditionalInformations, turnsConfig) {
      var myReservations = routerReservations.filter(function (f) { return f.client });
      if (!reservationUtils.canTakeTurn(myReservations, queues, reservation.date)) return ons.notification.alert({ message: i18next.t('Usted ya tiene un turno para una fecha demasiado próxima a la seleccionada'), title: i18next.t('Atención') });
      if (myReservations.length && turnsConfig && turnsConfig.onlyOneTurn) return ons.notification.alert({ message: i18next.t('No se permite más de un turno por cliente'), title: i18next.t('Atención') })
      // guardo en extraData los queues de multimotivo para el mail
      if (!reservation.parent && queues.length > 1) {
        reservation.extraData.childQueues = [];
        queues.forEach(function (f, index) {
          if (index === 0) return;
          reservation.extraData.childQueues.push({
            _id: f._id, name: f.name, attendingTime: f.router && f.router.attendingTime || { max: 30 }
          });
        })
      }

      //si es una reserva paga con medios de pago (no efectivo)
      if (queues.length === 1 && queues[0].router.payConfig && queues[0].router.payConfig.enabled)
        reservation.paymentData = await paymentFactory.initPayment(reservation._gateway, reservation.router, queues[0].router.payConfig.price)

      // guardo la primer reserva, para tener el id y agrupar el resto de las reservas con el parent(_id de la primer reserva)
      let savedReservation = await saveReservation(reservation, queues[0], null, null, allAdditionalInformations)
      if (savedReservation.queuePaymentEnabled && savedReservation.payment && savedReservation.payment.status === 'REDIRECT') return window.location.href = savedReservation.payment.redirectUrl
      for (let [index, queue] of queues.entries()) { //esto es para multimotivos
        if (index === 0) continue;
        var lastQueue = queues[index - 1];
        await saveReservation(reservation, queue, lastQueue, savedReservation._id, allAdditionalInformations);
      }

    }

    function saveReservation(reservation, queue, lastQueue, parent, allAdditionalInformations) {
      var reservationCopy = angular.copy(reservation);
      reservationCopy.queue = queue._id;
      reservationCopy.additionalInformation = reservation.additionalInformation; // se puso esto porque ai de tipo archivo, el angular.copy rompe los file
      reservationCopy.parent = parent;
      reservationCopy.extraData.motive = queue.name;
      if (parent) delete reservationCopy.extraData.childQueues;
      // TODO: ojo con esto, ver con nico
      if (allAdditionalInformations)
        reservationCopy.additionalInformation = buildAdditionalInformation(queue, allAdditionalInformations);
      if (lastQueue) reservationCopy.date = moment(lastQueue._reservationDate).add(lastQueue.router.attendingTime && lastQueue.router.attendingTime.max || 30, "minutes").toDate();
      queue._reservationDate = reservationCopy.date;
      var deferred = $q.defer(); // esto se hizo para que reservationResource siempre devuelva una promesa (hay un bug en doReservation que cuando hay un archivo, no devuelve promesa, TODO: solucionar esto)
      reservationResource.doReservation(reservationCopy, function (result) {
        var split = /(\D*)?(\d*)/.exec(result.serial);
        if (split) {
          reservationCopy.extraData.letter = result.letter = split[1];
          reservationCopy.extraData.place = result.place = split[2];
          reservationCopy.extraData.validationCode = result.validationCode;
        }
        reservationResource.doReservation({ _id: result._id, extraData: reservationCopy.extraData, _controller: 'turn', ignoreVeil: true }, Function.prototype);
        deferred.resolve(result);
      })
      return deferred.promise;
    }

    function buildAdditionalInformation(queue, allAdditionalInformations) {
      var additionalInformations = allAdditionalInformations.filter(function (ai) {
        if (!ai.queues || !ai.queues.length) return true;
        return ai.queues.find(function (f) { return f === queue._id });
      });
      var withFiles = additionalInformations.filter(function (f) { return f.type === 'FILE' });
      var withoutFiles = additionalInformations.filter(function (f) { return f.type !== 'FILE' });
      withoutFiles.forEach(function (f) { if (f.type && f.type === 'BOOLEAN' && !f.value) f.value = false; })
      if (!withFiles || !withFiles.length) return withoutFiles;
      var newAddInfo = [];
      withFiles.forEach(function (item) {
        var data = [];
        if (item.file && item.file.length) data = makeAdditionalInformation(item);
        else newAddInfo.push(item);
        if (data.length) data.forEach(function (f) { newAddInfo.push(f) });
      });
      return withoutFiles.concat(newAddInfo);
    }

    function makeAdditionalInformation(item) {
      var data = [];
      item.file.forEach(function (file) {
        var info = JSON.parse(JSON.stringify(item));
        info.file = file;
        info._filename = file.name;
        data.push(info);
      })
      return data;
    }

    /*
      {
        router: Objeto completo de router,
        mainQueue: Objeto completo de queue (si es motivo simple),
        queues: Array de queues si es multimotivo (enviar solo: mainQueue ó queues),
        slots: Cantidad de lugares a reservar,
        currentReservations: Array de objetos de reservas actuales para esa sucursal,
        holidays: Array de objetos de holidays - Enviar los que tienen routers como los que no tienen, esta funcion filtra lo necesario,
        posTables: Array de objetos de posTables,
        maxDays: cant de días hacia adelante que se va a calcular
      }
    */
    /* Devuelve: un array con fechas donde cada una tiene un array de horas en que hay disponibilidad
    [{
      date: Fecha con hora 00:00,
      today: Boolean si el día es hoy,
      tomorrow: Boolean si el día es mañana,
      hours: -- Array de horas de el día de la reserva
        [{
          start: Fecha con hora de inicio del intervalo,
          end: Fecha con hora de fin de intervalo,
          dowInterval: dowInterval
        }]
    }]
    cfg = { router, mainQueue, queues, slots, currentReservations, holidays, posTables, maxDays, includeFullDays }
    */
    function calculateAvailability(cfg) {
      var router = cfg.router; var mainQueue = cfg.mainQueue; var queues = cfg.queues; var slots = cfg.slots;
      var currentReservations = cfg.currentReservations; var holidays = cfg.holidays; var posTables = cfg.posTables;
      var maxDays = cfg.maxDays; var posAreas = cfg.posAreas; var includeFullDays = cfg.includeFullDays
      var thisDay = moment().utcOffset(router.utcOffset).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      var today = thisDay.clone().set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });
      var tomorrow = today.clone().add(1, 'day');
      var maxDate = thisDay.clone().add(maxDays || 45, 'days');
      var reservationConfig = router.reservationConfig;
      var resultDays = [];
      if (!reservationConfig || !reservationConfig.dow) return [];
      while (thisDay < maxDate) {
        var dow = thisDay.day()
        var allHours = generateHours(reservationConfig.dow[dow], mainQueue, thisDay, reservationConfig, router, holidays);
        var item = {
          date: thisDay.toDate(),
          today: today > thisDay,
          tomorrow: tomorrow > thisDay && today < thisDay,
          hours: []
        }
        //- Se agrega esta bandera solo para la funcionaliad de Lista de Espera
        if (includeFullDays && (allHours.length && allHours.some(h => h.dowInterval?.slots >= 1)))
          item.onFullDays = true
        item.hours = allHours.filter(function (hour) {
          // se tuvo que hacer esto para que sea compatible el modo567, que requiere que checkallmodes devuelva la mesa
          if (queues.length === 1) {
            const availableSlots = isMode234(mainQueue, router) ? getAvailableSlotsMode234(hour.start, mainQueue, router, currentReservations) : null
            hour.availableSlots = availableSlots
            var result = checkAllModes(hour.start, mainQueue, currentReservations, router, slots, posTables, holidays, posAreas, availableSlots);
            if (result && isMode567(mainQueue, queues)) {
              if (!result || !result._id) throw new PentaError('Error al obtener la mesa en el cálculo de disponibilidad')
              hour.posTable = result
            };
            return result;
          }
          return checkAllModesMultiQueue(hour.start, queues, router, currentReservations, slots, posTables, holidays);
        })
        //- Esta bandera es para marcar el dia que esta completo.
        if (item.onFullDays && !item.hours?.length) item.isFullDay = true
        resultDays.push(item);
        thisDay.add(1, 'day');
      }
      resultDays.forEach(function (day) { day.hours.sort(function (a, b) { return a.start - b.start }); })
      var today = resultDays.find(function (f) { return f.today })
      // Si al día de hoy no le quedan turnos a futuro, no lo marco como disponible.
      if (today && today.hours && !today.hours.find(function (f) { return f.start > new Date() }))
        // si no quedan turnos futuros y tengo activado el motivo con lista de espera
        // pongo a 0 las hours y lo marco como isFullDays
        if (today.onFullDays) {
          today.hours.length = 0
          today.isFullDay = true
        } else resultDays.splice(resultDays.indexOf(today), 1)
      // Si tengo activado la Lista de Espera debo devolver tambien aquellos dias que estan completos. 
      return resultDays.filter(function (f) { return f.hours.length || (f.onFullDays && f.isFullDay) });
    }

    function hoursSlotAvailability(hours, queueId) {
      if (!hours?.length || !queueId) return false
      return hours.some(hour => hour.dowInterval && hour.dowInterval >= 1)
    }

    function isMode234(queue, router) {
      const queueRouter = queue.router || queue.routers.find(f => f._id === router._id)
      if (!queueRouter) return true
      return !queueRouter.mode || queueRouter.mode === 'MODE2' || queueRouter.mode === 'MODE3' || queueRouter.mode === 'MODE4'
    }

    function getAvailableSlotsMode234(date, queue, router, routerReservations) {
      const dowInterval = getDowInterval(date, queue, router);
      if (!dowInterval) return 0; // no hay lugar

      const discountSlots = routerReservations.reduce((acc, reservation) => {
        if (reservation.queue !== queue._id) return acc
        if (typeof reservation.date.getTime !== 'function') reservation.date = new Date(reservation.date)
        const dowIntervalStartEnd = getDowIntervalStartEnd(date, router, dowInterval)
        if (dowIntervalStartEnd.start <= reservation.date && reservation.date < dowIntervalStartEnd.end) return acc += (reservation.slots || 1)
        return acc
      }, 0)
      return dowInterval.slots - discountSlots
    }

    function isMode567(selectedQueue, selectedQueues) {
      var queues = selectedQueue ? [selectedQueue] : selectedQueues;
      var result = queues.find(function (queue) {
        return queue && (queue.router.mode === 'MODE5' || queue.router.mode === 'MODE6' || queue.router.mode === 'MODE7')
      })
      if (result && selectedQueues.length > 1) throw new PentaError('Los modos 5, 6, 7 no son compatibles con multimotivos por el momento')
      return result;
    }

  }
})(typeof exports !== 'undefined' && exports)
