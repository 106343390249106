'use strict';
(function () {
  var _controller = 'turns.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', '$interval', '$q', '$filter', 'reservation.resource', 'router.resource', 'flyer.resource', 'flyer.factory', '$timeout', 'hq.socket', 'reservationStats.resource', 'bottomLogin.modal', 'appRate.modal', 'holiday.resource', 'posTable.resource', 'reservation.factory', 'queue.resource'];
  function controller($scope, $interval, $q, $filter, turnResource, routerResource, flyerResource, flyerFactory, $timeout, hqSocket, reservationStats, loginModal, appRateModal, holidayResource, posTableResource, reservationFactory, queueResource) {

    /// METHODS
    $scope.newTurn = newTurn;
    $scope.goTo = goTo;
    $scope.cancelTurn = cancelTurn;
    $scope.turnTracking = turnTracking;
    $scope.openChat = openChat;
    $scope.refresh = refresh;
    $scope.openLoginModal = openLoginModal;
    $scope.objTypeArr = objTypeArr;
    var debouncedRefresh = debounce(refresh, 1000)
    var debouncedRouter = debounce(refreshRouter, 500)
    var debouncedReservationStats = debounce(function () { reservationStatistics($scope.turns && $scope.turns[0]) }, 1000)

    /// VARS
    $scope.cIndex = 0;
    $scope.utcOffset = new Date().getTimezoneOffset()
    $scope.utcOffset = ($scope.utcOffset > 0 ? '-' : '') + padStart(Math.floor($scope.utcOffset / 60)) + padStart($scope.utcOffset % 60)
    var myself = appNavigator.topPage.myself = new Object();
    $scope.currentRouter = null;
    $scope.turns = [];
    $scope.stats = null;
    $scope.routerStats = null;
    $scope.routers = routerResource.query({ _controller: _controller });
    $scope.flyerResource = flyerResource;
    $scope.turnCarousel = null;
    var compatibilityAlertShown = false;
    var interval = null;
    var event = window.queryStrings.event;
    var turn = window.queryStrings.turn;

    /// si el pago de una reserva falla, el usuario vuelve acá con un mensaje de error
    if (window.queryStrings && window.queryStrings.payError) {
      window.queryStrings.payError = undefined;
      ons.notification.alert({
        title: i18next.t('Ocurrió un problema al realizar el pago'),
        message: i18next.t(window.queryStrings.payError ? atob(window.queryStrings.payError) : i18next.t('Error no especificado'))
      });
    }
    // WATCHERS
    $scope.$on('$destroy', onDestroy)
    $scope.$on('login', refresh);
    $scope.$on('defaultRouter', debouncedRouter);
    hqSocket.on('reconnect', onReconnect);
    hqSocket.on('RESERVATION-UPSERT', reservationUpsert);

    /// INIT
    interval = $interval(debouncedReservationStats, 3 * 60 * 1000); //Actualizo los datos estadísticos cada 3 minutos
    if (!profile._id && !profile.enterprise?.pwaConfig?.requiredPrompt?.enabled) openLoginModal()
    debouncedRouter();
    refreshFlyers();
    if (profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.menuItems && profile.enterprise.pwaConfig.menuItems.enableChat === 'CHAT-VIDEO')
      checkCompatibility();

    /////////////////////
    function padStart(str) { return ('0' + str).substr(-2) }

    function onReconnect() {
      debouncedRouter();
      refreshFlyers();
    }

    function refreshRouter() {
      var currentRouter = $scope.$root.defaultRouter;
      routerResource.get({ _id: currentRouter, _controller: _controller }, function (result) {
        $scope.currentRouter = result;
        if ($scope.currentRouter && event) $timeout(function () { newTurn() }, 500);
        if ($scope.currentRouter && turn) $timeout(function () { newTurn() }, 500);
        refreshFlyers();
        refresh();
      });
    }

    async function refresh(ignoreVeil) {
      try {
        if (!$scope.currentRouter) return console.error('ERR_NO_HAY_CURRENT_ROUTER')
        if (!$scope.$root.profile._id) return
        let turns = await turnResource.query({ myReservations: true, _controller: _controller, ignoreVeil: ignoreVeil }).$promise
        // Si había turnos y ahora no hay es porque terminó el turno, ya que cancelar lo borra de la lista sin hacer refresh
        if ($scope.turns && $scope.turns.length && turns && !turns.length) appRateModal.open('turnClosed')
        $scope.turns = turns;
        //Cargo los turnos hijos en caso de ser multiturno
        $scope.turns.forEach(function (item) {
          Object.defineProperty(item, '_childrens', { value: getTurnGroup(item, turns), enumerable: false })
        })

        if (turns.length) {
          debouncedReservationStats() // inicializo las estadísticas con un debounce por si el $interval está ejecutando tambien la misma func
          $timeout(function () {
            if (profile._id && profile.enterprise && profile.enterprise.pwaConfig &&
              profile.enterprise.pwaConfig.menuItems && profile.enterprise.pwaConfig.menuItems.enableChat === 'CHAT-VIDEO') {
              if (!checkCompatibility(true)) return;
              chatUtils.availableMediaDevices(i18next.t('Para hacer uso de la atención remota necesitamos acceso a tus dispositivos multimedia'), function () { })
            }
          }, 1000)
          if (myself === appNavigator.topPage.myself) autoOpenChat();
        }

        $scope.$applyAsync();
      }
      catch (err) {
        $scope.$applyAsync(() => { throw err });
      }
    }

    function reservationStatistics(currentTurn) {
      if (!currentTurn) return
      if (currentTurn.queueStatus === "ATTENDING") return //- si el turno esta atendido no obtengo estadisticas.
      currentTurn.date = new Date(currentTurn.date);
      var turnDate = currentTurn.date - Date.now(); //- Diferencia entre la hora del turno y la hora actual.
      var oneHour = 3600000; //- equivalente en milisegundos de una hora.
      if (turnDate > oneHour) return //- el turno no esta dentro del rango de estadisticas ( 1 hora previa ).
      reservationStats.get({ router: $scope.currentRouter._id, turnDate: $scope.turns[0]?.date, _controller: _controller, ignoreVeil: true }, function (stats) {
        if (stats) avgReservationStats(currentTurn, stats);
      })
    }

    function avgReservationStats(currentTurn, stats) {
      if ((isNaN(stats?.clients) && !stats?.avgWaiting) || !currentTurn) return
      var reservationDelay = -5; //- Demora, luego de llegar a la hora de la reserva, para dar aviso de la demora. (numero negativo).
      var leftMinutes = ((Date.now() - currentTurn.date) / 1000 / 60) //- minutos que pasaron desde la reserva hasta ahora.
      var avgWaiting = stats.avgWaiting - leftMinutes; //- minutos que hay entre lo ya esperado y el tiempo de espera promedio.
      if (!currentTurn._stats) currentTurn._stats = { client: null, avgWaiting: null }
      currentTurn._stats.clients = stats.clients;
      currentTurn._stats.avgWaiting = avgWaiting;
      if (avgWaiting && Number(avgWaiting.toFixed(1)) <= reservationDelay) {
        if (appStorage.get('reservationAlertShownId') === currentTurn._id) return
        appStorage.set('reservationAlertShownId', currentTurn._id);
        ons.notification.alert({ title: i18next.t("Disculpe la demora"), message: i18next.t('En breve será atendido, manténgase atento a la APP') });
      }
    }

    function newTurn(turnToModify) {
      // si tengo seteada la bandera "dontAskImmediateTurn" me fijo que haya una sola sucursal, un solo motivo y saco el turno inmediato automaticamente
      if (!turnToModify && profile.enterprise && profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.turnsConfig && profile.enterprise.pwaConfig.turnsConfig.dontAskImmediateTurn) {
        return inmediateTurn();
      }
      else {
        if (!$scope.currentRouter) return;
        appNavigator.pushPage('turns/newTurn/newTurn.jade', { data: { onClose: reservationUpsert, currentRouter: $scope.currentRouter, turnToModify: turnToModify } });
      }
    }

    function inmediateTurn() {
      var routersFiltered = $scope.routers.filter(function (r) { return !r.hidden }) // filtro las sucursales que están habilitadas
      if (routersFiltered.length > 1) throw new PentaError('Si tiene mas de una sucursal no debería activar el turno inmediato sin preguntar nada');
      var router = routerResource.get({ _id: routersFiltered[0]._id, _controller: _controller });
      var queues = queueResource.query({ "routers._id": routersFiltered[0]._id, _controller: _controller });
      var routerReservations = turnResource.query({ router: routersFiltered[0]._id, maxDate: moment().set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }).add(45, 'days').toDate(), _controller: _controller });
      var posTables = posTableResource.query({ router: routersFiltered[0]._id, _controller: _controller });
      var holidays = holidayResource.query({ _controller: _controller });
      $q.all([router.$promise, queues.$promise, routerReservations.$promise, posTables.$promise, holidays.$promise])
        .then(function () {
          queues = queues.filter(function (queue) { return queue.routers && queue.routers[0] && queue.routers[0].target && queue.routers[0].target.pwa });
          if (queues.length > 1) throw new PentaError('Si tiene mas de un motivo no debería activar el turno inmediato sin preguntar nada');
          if (reservationFactory.checkAllModes(Date.now(), queues[0], routerReservations, router, 1, posTables, holidays)) return takeImmediateTurn(queues[0], router, routerReservations);
          return ons.notification.alert({ title: i18next.t("No hay disponibilidad"), message: i18next.t('Disculpe, en este momento no hay lugares disponibles') });
        })
    }

    async function takeImmediateTurn(queue, router, routerReservations) {
      try {
        var queues = [queue];
        var reservation = {
          email: profile.email,
          extraData: {
            externalId: router.externalId,
            ticketNow: true,
          },
          inmediate: true,
          date: Date.now(),
          router: router._id
        };
        await reservationFactory.takeTurn(queues, routerReservations, reservation, [])
        ////
        $scope.$applyAsync();
      }
      catch (err) {
        $scope.$applyAsync(() => { throw err });
      }
    }

    /// Se usaba en mediaturno antes, ya no se usa
    function turnTracking(turn) {
      var parts = turn.extraData.externalId.split('-');
      var empresa = parts[0];
      var sucursal = parts[1];

      var turnRouter = $scope.routers.find(function (router) { return router._id == turn.router });

      var queryStringParams = {
        idEmpresa: empresa,
        idSucursal: sucursal,
        description: turnRouter.name,
        letter: turn.extraData.letter,
        place: turn.extraData.place,
        production: turn.extraData.production
      }

      appNavigator.pushPage('turns/turnTracking/turnTracking.jade', { data: { onClose: refresh, currentRouter: $scope.currentRouter, params: queryStringParams } });
    }

    function cancelTurn(turn, index, message) {
      if (turn && turn.queuePaymentEnabled && turn.price && turn.payment.status === 'APPROVED') {
        return ons.notification.alert({ title: i18next.t("Atención: reserva paga"), message: i18next.t('No se puede cancelar su reserva porque es paga. Deberá solicitar su dinero y la cancelación del mismo comunicándose con la empresa.') });
      }
      ons.notification.confirm({ message: message, title: 'Confirmación', buttonLabels: ['No', 'Si'] })
        .then(function (confirm) {
          if (confirm) {
            var turnGroup = getTurnGroup(turn, $scope.turns);
            if (!turnGroup.length) return cancelReservation(turn, index);
            if (isLast(turn, turnGroup)) { //si el turno es el ultimo, no hago nada, solo cancelo
              cancelReservation(turn, index)
            } else { // si el turno es el primero o uno del medio, reordeno hacia arriba y cancelo
              cancelAndReorderTurns(turn, turnGroup); // debería cancelar y reordenar
            }
          }
        })
    }

    function cancelReservation(turn, index, dontSendCancelEmail) {
      return turnResource.cancelReservation({ _id: turn._id, dontSendCancelEmail: dontSendCancelEmail, _controller: _controller }, function () {
        // Si se cambia este splice por un refresh notar que en refresh va a ejecutar appRateModal.open
        // esto va a hacer que se pida un comentario de la app al momento en que se cancela un turno
        $scope.turns.splice(index, 1);
      }).$promise
    }

    function getTurnGroup(turn, turns) {
      if (!turns) throw new PentaError('No hay reservas');
      if (!turn) throw new PentaError('La reserva que intenta cancelar no existe');
      var turnGroup = [];
      // si turn no tiene parent, busco a los hijos (y él mismo)
      if (!turn.parent) {
        turns.forEach(function (f) { if (f.parent === turn._id) turnGroup.push(f) });
        turnGroup.unshift(turn);
      }
      // si tiene parent, busco al padre y a los hermanos
      else turns.forEach(function (f) { if (f.parent === turn.parent || f._id === turn.parent) turnGroup.push(f); })
      if (turnGroup.length < 2) return []; // si no tiene parent y tampoco hijos/hermanos no es un multiturno

      return turnGroup;
    }

    function isLast(turn, turnGroup) {
      // si el turno que quiero cancelar es el último
      var turnGroupSorted = turnGroup.sort(function (a, b) { return new Date(a.date) - new Date(b.date) });
      return turnGroupSorted[turnGroupSorted.length - 1]._id === turn._id;
    }

    function cancelAndReorderTurns(turnToCancel, turnGroup) {
      var newTurnGroup = turnGroup.filter(function (f) { return f._id !== turnToCancel._id }).sort(function (a, b) { return new Date(a.date) - new Date(b.date) });
      var mainTurn = newTurnGroup[0];
      // busco en db todo lo necesario
      var queues = queueResource.query({ router: mainTurn.router, _controller: _controller });
      var router = routerResource.get({ _id: mainTurn.router, _controller: _controller });
      var routerReservations = turnResource.query({ router: mainTurn.router, maxDate: moment().set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }).add(45, 'days').toDate(), _controller: _controller });
      var posTables = posTableResource.query({ router: mainTurn.router, _controller: _controller });
      var holidays = holidayResource.query({ _controller: _controller });
      $q.all([queues.$promise, router.$promise, routerReservations.$promise, posTables.$promise, holidays.$promise]).then(function () {
        var reservationSlots = mainTurn.slots;
        var newTurnQueues = newTurnGroup.map(function (m) { return queues.find(function (f) { return f._id === m.queue }) });
        holidays.forEach(function (e) { e.dateString = moment(e.date).utcOffset('0000').format('YYYYMMDD') });
        var filteredRouterReservations = routerReservations.filter(function (f) { return !turnGroup.find(function (turn) { return turn._id === f._id }) }) //quito mis propios turnos de este grupo
        // busco disponibilidad de turnos
        if (reservationFactory.checkAllModesMultiQueue(turnGroup[0].date, newTurnQueues, router, filteredRouterReservations, reservationSlots, posTables, holidays)) {
          restoreAIFiles(turnGroup[0], function () {
            $q.all(turnGroup.map(function (turn, index) {
              var dontSendCancelEmail = (turn._id !== turnToCancel._id) // no mandar mail cuando no es el turno que elegí cancelar
              return cancelReservation(turn, index, dontSendCancelEmail);
            })).then(async function () {
              try {
                var newMainTurn = angular.copy(mainTurn);
                newMainTurn.date = turnGroup[0].date;
                newMainTurn.additionalInformation = turnGroup[0].additionalInformation;
                delete newMainTurn._id;
                delete newMainTurn.parent;
                await reservationFactory.takeTurn(newTurnQueues, filteredRouterReservations, newMainTurn, null);
                ons.notification.toast(i18next.t('Se reordenaron los turnos a las ') + $filter('utcOffset')(newMainTurn.date, router.utcOffset, 'HH:mm'), { timeout: 3000 });
                refresh()
                ///
                $scope.$applyAsync();
              }
              catch (err) {
                $scope.$applyAsync(() => { throw err });
              }
            }).catch(function (err) { if (err) throw new PentaError(err) })
          });
        } else {
          //si no encuentro lugar cancelo todos los turnos
          ons.notification.confirm({ message: i18next.t('No se pueden reordenar todos los turnos de forma contigua, quiere cancelarlos a todos?'), title: 'Confirmación', buttonLabels: ['No', 'Si'] })
            .then(function (confirm) {
              if (confirm)
                $q.all(turnGroup.map(function (turn, index) {
                  return cancelReservation(turn, index);
                })).then(function () {
                  refresh();
                })
            }).catch(function (err) { if (err) throw new PentaError(err) })
        }
      })
    }

    function restoreAIFiles(reservation, cb) {
      if (!reservation || !reservation.additionalInformation) return cb()
      $q.all(reservation.additionalInformation.filter(function (f) { return f.type === 'FILE' && f.value }).map(function (ai) {
        var result = getAIFile(ai, reservation);
        result.then(function (file) {
          ai.file = file;
          ai._filename = ai.value;
        })
        return result;
      })).then(cb).catch(function (err) { if (err) throw new PentaError(err) })
    }

    function getAIFile(ai, reservation) {
      var deferred = $q.defer();
      var url = '/progressiveApp/api/reservation';
      url += '?_id=' + reservation._id;
      url += '&fileName=' + ai.value;
      url += '&enterprise=' + window.enterprise;
      url += '&_file=' + true;
      load(url, function (data) {
        urltoFile(data, ai.value, ai.mimeType).then(function (file) {
          deferred.resolve(file)
        });
      })
      return deferred.promise;
    }

    function load(url, callback) {
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'blob';
      request.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = function () {
          callback(this.result)
        };
      };
      request.send();
    }

    function urltoFile(url, filename, mimeType) {
      return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
      );
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
      hqSocket.removeListener('RESERVATION-UPSERT', reservationUpsert);
      hqSocket.removeListener('reconnect', onReconnect);
      $interval.cancel(interval);
    }

    function goTo(url) {
      var win = window.open(url, '_blank');
      win.focus();
    }

    function reservationUpsert() { debouncedRefresh(true) }

    function refreshFlyers() {
      flyerFactory.getFlyers('APP-TURN').then(function (flyers) {
        $scope.turnCarousel = window.flyerFilter(flyers, $scope.$root.defaultRouter);
        $scope.$root.carouselAutoscroll($scope, $scope.carousel, $scope.turnCarousel)
      }).catch(function (err) { if (err) throw new PentaError(err) });
    }

    function autoOpenChat() {
      if ($scope.$root.chatOpened) return
      if (!$scope.turns || !$scope.turns.length) return
      //if ($scope.turns.length > 1) return //Abre el chat siempre, o es confuso porque no se sabe que te estan hablando //- Solamente se abre el chat automaticamente cuando hay una sola reserva.
      var turn = $scope.turns.find(function (f) { return f.chat && f.chat.clientBadge >= 1 })
      if (turn) openChat(turn, true)
    }

    function openChat(turn, forced) {
      if (forced || (turn.queueStatus && turn.queueStatus === "ATTENDING")) return appNavigator.pushPage('chat/chat.jade', { data: { title: 'Turno ' + turn.serial, relatedObject: turn._id, relatedObjectType: 'reservation', user: turn.queueUser } })
      var message = i18next.t('Los mensajes serán respondidos cuando llegue su turno') + '. <br><br>' + i18next.t('Muchas Gracias')
      ons.notification.confirm({
        message: message,
        title: i18next.t('Aviso'),
        buttonLabels: [i18next.t('Cerrar'), i18next.t('Abrir Chat')]
      })
        .then(function (confirm) {
          var title = profile.enterprise.pwaConfig.turnsConfig && profile.enterprise.pwaConfig.turnsConfig.turnSingularAs || i18next.t('turno');
          if (confirm) appNavigator.pushPage('chat/chat.jade', { data: { title: title + ' ' + turn.serial, relatedObject: turn._id, relatedObjectType: 'reservation' } })
        })
    }

    function checkCompatibility() {
      var result = $scope.$root.checkVideoconferenceCompatibility();
      if (result.alert.show && !window.checkCompatibilityAlertShow) {
        window.checkCompatibilityAlertShow = true;
        showAlertDebounced(result.alert);
      }
      return result.compatibility;
    }

    // alert={
    //   title: i18next.t('text'),
    //   message: i18next.t('text'),
    // }
    function showAlertDebounced(alert) {
      if (compatibilityAlertShown) return
      compatibilityAlertShown = true;
      $timeout(function () { compatibilityAlertShown = false }, 10000);
      ons.notification.alert({
        title: alert.title,
        message: alert.message,
      })
    }

    function debounce(func, wait, immediate) {
      var timeout;
      return function () {
        var context = this, args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }

    function openLoginModal() {
      loginModal.open({ title: i18next.t('Debes iniciar sesión') })
    }

    function objTypeArr(obj) {
      if (!obj) return false
      if (angular.isArray(obj)) return true
      return false
    }
    //// FIN CONTROLLER
  }
})();
